import shared from '@/shared/lib/locales/pl/shared.json';
import prices from '@/shared/lib/locales/pl/prices.json';
import payments from '@/shared/lib/locales/pl/payments.json';
import deliveries from '@/shared/lib/locales/pl/deliveries.json';
import onlineOrdersStatuses from '@/shared/lib/locales/pl/onlineOrdersStatuses.json';

import popups from '@/shared/lib/support/popups/locales/popupsLocalePL.json';
import validation from '@/shared/lib/support/validation/locales/validationLocalePL.json';

import app from '@/app/locales/pl/app.json';

import auth from '@/app/modules/auth/locales/authLocalePL.json';

export default {
  ...shared,
  ...prices,
  ...payments,
  ...deliveries,
  ...onlineOrdersStatuses,

  ...popups,
  ...validation,
  ...app,

  ...auth,
};
